const CONTENT = {
  heading1: 'Kündigung - Bitte helfen Sie uns Ihren Vertrag zu identifizieren ',
  cancelation: 'Kuendigungsauftrag - Geraeteschutz beenden',
  callus: 'Wir finden es schade dass Sie Ihre Versicherung kuendigen wollen. Sie haben noch Fragen? Bitte rufen Sie uns an',
  timings: 'Sie erreichen uns von Montag bis Samstag zwischen 8:00 18:00 unter:',
  phone: '+43 1 206092917',
  submit: 'Weiter',
  successMsg: 'Anfrage erfolgreich gesendet',
  failMsg: 'Bitte versuche es erneut',
  email: 'cs.hk@careplus.co',
  disclaimer: 'Disclaimer',
  termsAndConditions: 'Terms and Conditions',
  backToForm: 'Zurück'

}

export default CONTENT

