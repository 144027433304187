import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import styles from './textbox.module.scss';
import alertIcon from '../../../assets/images/alert_icon.svg';

function TextBox(props) {
  // constructor(props) {
  //   super(props);
  //   console.log('rendering...')
  //   this.state = {
  //     value: props.text || '',
  //     // errorMsg: 'Erforderliches Field.',
  //     // error: false,
  //   };

  // //   const regexValidation = props.validations.find(
  // //     (value) => value.name === 'ALLOWED_CHARACTERS',
  // //   );
  // //   if (regexValidation) {
  // //     this.keyRegex = new RegExp(`[${regexValidation.value}]`);
  // //     this.pasteRegex = new RegExp(`^[${regexValidation.value}]*$`);
  // //   }
  // }

  const [value, setValue] = useState(props.text || '')

  const handleChange = (e) => {
    // e.persist();
    // this.setState({ value: e.target.value });
    setValue(e.target.value)
    props.onChange(e);
  }

  

  // checkValidity() {
  //   const validationRes = this.isValid();
  //   console.log('validationRes: ', validationRes)
  //   this.setState({
  //     error: !validationRes.valid,
  //     errorMsg: validationRes.errorMsg || this.state.errorMsg,
  //   });
  // }

    return (
      <div className={styles.textWrapper}>
        <label>{props.label}</label>
        <input
          // onInput={(event) => this.handleChange(event)}
          className={`w-100 ${styles.tabInput} ${props.className} ${
            props.errorMsg && styles.inputError
          }`}
          type={props.type}
          value={value}
          onChange={(event) => handleChange(event)}
          // onChange={() => this.checkValidity()}
        />
        {props.showErrorIcon && props.errorMsg &&
          <span className={`${styles.rightAlertIcon}`}>
            <img src={alertIcon} alt='alert icon' width="20" height="20" />
          </span>
        }
        {props.bottomLabel && <p className='bottom'>{props.bottomLabel}</p>}
        {props.hasIcon && (
          <div
            onClick={() => props.onIconClick()}
            className={`${styles.inputIcon}`}
            role="button"
            tabIndex="-1"
          >
            <img
              src={props.icon}
              alt="Eye"
              style={{ width: '30px', height: '30px' }}
            />
          </div>
        )}
        {props.errorMsg && (
          <p className={`${props.className} ${styles.error}`}>
            {props.errorMsg}
          </p>
        )}
      </div>
    );
  }

TextBox.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  validations: PropTypes.array,
  hasIcon: PropTypes.bool,
  icon: PropTypes.string,
  language: PropTypes.string,
  onIconClick: PropTypes.func,
};

TextBox.defaultProps = {
  text: '',
  type: 'text',
  placeholder: '',
  className: '',
  validations: [],
  hasIcon: false,
  icon: '',
  onIconClick: () => {},
};


export default TextBox;
