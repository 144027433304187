import React from "react"
import bannerMobile from "../../assets/images/banner_home_mobile2.jpg"
import bannerTablet from "../../assets/images/banner_home_tablet2.jpg"
import bannerDesktop from "../../assets/images/banner_home_desktop2.jpg"
import getLocale from '../../assets/content'
import styles from './bannercomp.modules.scss'



const HomeBanner = () => {
  return (
    <div className='home-banner-comp'>
      <img src={bannerMobile} className="display-mobile2" alt="" />
      <img src={bannerTablet} className="display-tablet2" alt="" />
      <img src={bannerDesktop} className="display-desktop2" alt="" />
      <div className="banner-content">
        <div className="container">
          <p className="banner-heading font-bold">
            {getLocale()['heading1']}
          </p>
        </div>
      </div>
    </div>
  )
}

export default HomeBanner
