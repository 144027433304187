import React, { useState } from 'react'
import Layout from '../../components/shared/layout'
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import ContainerComp from "../../components/ContainerComp/index";
import SuccessComp from "../../components/SuccessComp/index";
// import Footer from '../components/Footer/index';
import BannerComp from '../../components/BannerComp/index'

const CancellationForm = () => {

  const [linksVisible, setLinksVisible] = useState(false)
  const [success, setSuccess] = useState(undefined)
  const [animate, setAnimate] = useState(false)

  const API_URL = process.env.REACT_APP_API_URL || 'https://crm-uat.amtrust.asia:8085'
  const X_API_KEY = process.env.X_API_KEY || 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzYW1zdW5nIiwicm9sZSI6IjEwLDMsMyIsInBvc3RhbEFkZHJlc3MiOiJyZWdpb25hbCIsImF1dGhVcGRhdGlvblJlcXVpcmVkIjpmYWxzZSwiZ2lkTnVtYmVyIjoiNTA1IiwiaWF0IjoxNTkwNzY2OTkzfQ.l6KO_wxpYQsXIedE-9XU7YfH_Z8WQrKJHblqyJjEfyj_NPBnB_T5n-gEGBZClzXF6-vK2NmaHD-DGcygIjyMwQ'
  const refID = uuidv4()

  const jsonData = {
    "data": {
    },
    "from": "no-reply@bolttech.at",
    "partnerId": "ATDREIATELNA01",
    "subject": `Webform Cancellation Request Registered ${refID}`,
    "templateName": "drei/webformcancellation.html",
    "text": "",
  }
  const submitData = (formData) => {
    jsonData.data = {...formData, "randomId": refID}
    jsonData.to = [formData.email, process.env.TO_EMAIL||'drei-device-support@bolttech.at']
    //call api
    fetch(`${API_URL}/send-mail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': X_API_KEY
      },
      body: JSON.stringify(jsonData)
    })
    .then(data => data.json())
    .then(resp => {
      console.log(resp)
      setSuccess(!resp.error)
      setAnimate(true)
    })
  }

  const pageAnimation = {
    start: {
      x: 0,
      position: 'relative'
    },
    end: {
        x: -2000,
        transition: {
            duration: .5
        },
        position: 'absolute'
    }
  }

  const goBackToForm = () => {
    setSuccess(undefined)
    setAnimate(false)
  }

    return (
        <div className="cancellation-page">
            <Layout header={true} footer={true}>
                <motion.div initial={{ x: 0 }}  animate={(success === true || success === false) ? 'end' : 'start'} variants={pageAnimation}>
                    <BannerComp />
                    <div className={`w-100 overflow-hidden `}>
                        <ContainerComp submitData={submitData} success/>
                    </div>
                </motion.div>
                <SuccessComp success={success} animate={animate} goBackToForm={goBackToForm} refID={refID} />
            </Layout>
        </div>
    )
}

export default CancellationForm