import React from 'react'
import { motion } from 'framer-motion';
import getLocale from '../../assets/content'
import styles from './generic-banner.modules.scss'

const pageAnimation = {
	start: {
		x: 2000,
		display: 'none',
	},
	end: {
		x: 0,
		display: 'block',
		transition: {
			duration: 1
		},
	},
	transition: {
		duration: .5
	}
}


function GenericBanner({bannerDesktop, bannerMobile, bannerTablet, headerId, msg, backToFormId, animate, goBackToForm}) {
    return (
			<motion.div className="generic-banner"   animate={animate === false ? 'start' : 'end'} variants={pageAnimation}>
				<img src={bannerDesktop} className="display-desktop3" alt="desktop banner" />
				<img src={bannerMobile} className="display-mobile3" alt="mobile banner" />
				<img src={bannerTablet} className="display-tablet3" alt="tablet banner" />
				<div className="banner-content">
					<div className="container">
						<p className="banner-heading font-bold">
							{getLocale()[headerId]}
						</p>
						<p className="banner-subheading font-light">
							{msg}
						</p>
						{backToFormId && (
							<p className="button-wrapper">
								<button value="Send cancellation request" className='mt-5 btn btn-primary back-btn' onClick={() => goBackToForm()}><span>{getLocale()[backToFormId]}</span></button>
							</p>
						)}
					</div>
				</div>
			</motion.div>
    )
}

export default GenericBanner