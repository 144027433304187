import React, { useState } from 'react';
import getLocale from '../../../assets/content';
import DropDownComp from '../DropDown';
import TextBox from '../TextBox/index';
import styles from './form.modules.scss';

function Form({submitData}) {
    const [formData, setFormData] = useState({})
    let initialQuestions = [
        {
            id: 1,
            key: 'email',
            value: '',
            label:'Email-Adresse',
            isValid: false,
            bottomLabel: 'Die bei Vertragsabschluss angegeben wurde.',
            validations: [{ name: 'EMAIL', errorMsg: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.' }]
        },
        {
            id: 2,
            key: 'firstName',
            value: '',
            label:'Vorname',
            isValid: false,
            validations: [{ name: 'REQUIRED', errorMsg: 'Erforderliches Field.'}]
        },
        {
            id: 3,
            key: 'lastName',
            value: '',
            label:'Nachname',
            isValid: false,
            validations: [{ name: 'REQUIRED', errorMsg: 'Erforderliches Field.'}]
        },
        {
            id: 4,
            key: 'mobileNo',
            value: '',
            label:'Handy-Nummer',
            inputType: 'number',
            isValid: false,
            validations: [{ name: 'MIN_LENGTH', value: 6, errorMsg: 'Bitte geben Sie eine gültige Handynummer ein.' }]
        },
        {
            id: 5,
            key: 'imei',
            value: '',
            label:'Seriennummer \\ IMEI',
            bottomLabel: 'Des versicherten Gerätes.',
            inputType: 'number',
            isValid: false,
            validations: [{ name: 'ONLY_NUMBERS' }, { name: 'EXACT_LENGTH', value: 15, errorMsg: 'Bitte geben Sie eine gültige 15-stellige IMEI-Nummer ein.' }, ]
        },
        {
            id: 6,
            key: 'insuranceNo',
            value: '',
            label:'Versicherungsvertragsnummer',
            isValid: false,
            validations: [{ name: 'MAX_LENGTH', value: 20, errorMsg: '' }, { name: 'REQUIRED', errorMsg: 'Erforderliches Field.'}]
        },
        {
            id: 7,
            key: 'reasonToQuit',
            value: 'Bitte wählen Sie ausn',
            label:'Was ist der Hauptgrund für Ihre Kündigung?',
            type: 'dropdown',
            selected: -1, 
            options: [
                { id: '1', value: 'Mein Telefon ist nicht mehr neu / wertvoll'},
                { id: '2', value: 'Ich brauchte die Versicherung nur für einen bestimmten Zeitraum'},
                { id: '3', value: 'Ich war mit dem Kundenerlebnis bei der Schadensfallmeldung nicht zufrieden'},
                { id: '4', value: 'Es ist zu teuer'},
                { id: '5', value: 'Ich habe mein Gerät aktualisiert und / oder habe das Gerät für das ich die Versicherung gekauft habe nicht mehr'},
                { id: '6', value: 'Ich habe eine andere Versicherung, die mein Gerät abdeckt'},
                { id: '7', value: 'Ich brauche die gekaufte Versicherungsdeckung nicht mehr'},
                { id: '8', value: 'Die gratis Laufzeit der Police ist abgelaufen'},
            ],
            selectedOption: 'Bitte wählen Sie ausn',
            validations: [{ name: 'REQUIRED', errorMsg: 'Erforderliches Field.'}]
        },
        {
            id: 8,
            key: 'reConsider',
            value: 'Bitte wählen Sie ausn',
            label:'UMFRAGE: Bitte helfen Sie uns, unseren Service zu verbessern. Wenn wir Ihren nächsten Schadensfall ohne Selbstbehalt bearbeiten würden, als Dankeschön, dass Sie ein treuer Kunde sind, würden Sie Ihre Kündigungsentscheidung überdenken?',
            type: 'dropdown',
            selected: -1, 
            options: [
                { id: '1', value: 'Ja'},
                { id: '2', value: 'Nien'}
            ],
            selectedOption: 'Bitte wählen Sie ausn',
            validations: [{ name: 'REQUIRED', errorMsg: 'Erforderliches Field.'}]
        }
    ]

    const [questions, setQuestions] = useState(initialQuestions)

    const updateState = (key, value) => {
        const newQuestions = questions
        for(let i=0; i<newQuestions.length; i++) {
            let question = newQuestions[i]
            if (question.key === key) {
                question.value = value
                break
            }
        }
        setFormData({
            ...formData,
            [key]: value
        })
        setQuestions(newQuestions)
    }

    const isValid = () => {
        let valid = true;
        let maxChar = null;
        let minChar = null;
        let errorMsg = null;
        let exactChar = null;
        let validation;
        let newQuestions = questions
        for (let i = 0; i < newQuestions.length; i++) {
            let question = newQuestions[i]
            for (let j=0; question.validations && j<question.validations.length; j++) {
                validation = question.validations[j];
                if (validation.name === 'MAX_LENGTH') {
                    maxChar = parseInt(validation.value, 10);
                    valid = question.value.length <= maxChar;
                    if (!valid) {
                        question.errorMsg = validation.errorMsg
                    } else {
                        question.errorMsg = null
                    }
                }

                if (validation.name === 'REQUIRED') {
                    if (question.type === 'dropdown') {
                        valid = question.selected > -1;
                        if (!valid) {
                            question.errorMsg = validation.errorMsg
                        } else {
                            question.errorMsg = null
                        }
                    } else {
                        valid = question.value.length > 0;
                        if (!valid) {
                            question.errorMsg = validation.errorMsg
                        } else {
                            question.errorMsg = null
                        }
                    }
                }
        
                if (validation.name === 'MIN_LENGTH') {
                    minChar = parseInt(validation.value, 10);
                    console.log('minChar: ', minChar)
                    valid = question.value.length >= minChar;
                    console.log('valid: ', valid)
                    if (!valid) {
                        question.errorMsg = validation.errorMsg

                    } else {
                        question.errorMsg = null
                    }
                }
        
                if (validation.name === 'EXACT_LENGTH') {
                    exactChar = parseInt(validation.value, 10);
                    console.log('value: ', question.value.length)
                    console.log('exactChar: ', exactChar)
                    valid = question.value.length === exactChar
                    
                    console.log('valid: ', valid)
                    if (!valid) {
                        question.errorMsg = validation.errorMsg
                    } else {
                        question.errorMsg = null
                    }
                    console.log('errorMsg: ', errorMsg)
                }
        
                if (validation.name === 'ONLY_NUMBERS') {
                    valid = !!Number(question.value);
                    if (!valid) {
                        question.errorMsg = validation.errorMsg
                    } else {
                        question.errorMsg = null
                    }
                }
        
                if (validation.name === 'EMAIL') {
                    console.log('question.value: ', question.value)
                    const regexp = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
                    valid = regexp.test(question.value);
                    if (!valid) {
                        question.errorMsg = validation.errorMsg
                    } else {
                        question.errorMsg = null
                    }
                }
            }
        }
        const newQ = [...newQuestions]
        setQuestions(newQ)
        console.log('newQuestions: ', newQuestions)
        const isInvalid = questions.filter(question => !!question.errorMsg)
        return isInvalid.length === 0
      }

    const formSubmit = (e) => {
        e.preventDefault()
        if (isValid()) {
            submitData(formData)
        }
    }

    const handleSelection = (key, optionId) => {
        let value
        for(var i=0; i<questions.length; i++) {
            let question = questions[i]
            if (question.type === 'dropdown' && question.key === key) {
                question.selected = optionId
                question.value = question.options[optionId].value
                value = question.options[optionId].value
                break
            }
        }
        setFormData({
            ...formData,
            [key]: value
        })
        const newQ = [...questions]
        setQuestions(newQ)
    }

    return (
        <form onSubmit={formSubmit}>
            {questions.map(question => (
                question.type === 'dropdown' ? (
                    <div className='inputField' key={question.id}>
                        <p>{question.label}</p>
                        <DropDownComp
                            key={question.id}
                            id={question.id}
                            className="dropdown"
                            data={question.options}
                            errorMsg={question.errorMsg}
                            size="big"
                            showErrorIcon={true}
                            defaultSelection={question.selectedOption}
                            handleChange={(id) => handleSelection(question.key, id)}
                        />
                    </div>
                ) : (
                    <div className='inputField' key={question.id}>
                        <TextBox
                            key={question.id}
                            id={question.id}
                            label={question.label}
                            text={question.text}
                            showErrorIcon={true}
                            type={question.inputType || 'text'}
                            errorMsg={question.errorMsg}
                            bottomLabel={question.bottomLabel}
                            onChange={(e) =>
                                updateState(question.key, e.target.value)
                            }
                        />
                    </div>
                )
            ))}
            <button  value="Send cancellation request" className={`buttonStyle mt-5`}>{getLocale()['submit']}</button>
        </form>
    )
}

export default Form
