import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import downarrow from '../../../assets/images/arrow-down.svg';
import chevronup from '../../../assets/images/icon-chevron-down.svg';
import styles from './dropdown.module.scss';
import alertIcon from '../../../assets/images/alert_icon.svg';

const DropDownComp = (props) => {

  const [options, setOptions] = useState(props.data)
  const defaultOptions = props.data
  let [selectedOption, setSelectedOption] = useState(props.defaultSelection)

  const [isClosed, setIsClosed] = useState(true);
  const [fontStyle, setFontStyle] = useState({fontSize: '12px'})

  useEffect(() => {
    if (props.size === 'big') {
      setFontStyle({fontSize: '14px'})
    }
    if (typeof props.defaultSelection === 'number') {
      setSelectedOption(options[props.defaultSelection].value)
      setCorrectOptions(options[props.defaultSelection].id)
    }
  }, [props.size])

  function handleOpen() {
    setIsClosed((closed) => !closed);
    document.removeEventListener('mouseup', handleOpen);
  }

  function handleDropdown() {
    setIsClosed((closed) => {
      let newClose = closed;
      if (closed) {
        setTimeout(() => {
          document.addEventListener('mouseup', handleOpen);
        }, 0)
      } else {
        newClose = !newClose;
      }
      return !newClose;
    });
  }

  const handleChange = (optionId) => {
    console.log('option: ', optionId)
    setCorrectOptions(optionId)
    props.handleChange(optionId)
  }

  const setCorrectOptions = (optionId) => {
    const newOptions = defaultOptions.filter((item) => optionId !== item.id);
    setOptions(newOptions)
    const selectedOption = defaultOptions.filter((item) => optionId === item.id);
    if (selectedOption[0])
      setSelectedOption(selectedOption[0].value)
  }

  return (
    <>
      <Dropdown as={ButtonGroup} className={`${styles.dropdown}`}>
        <Dropdown.Toggle
          variant=""
          id="dropdown-split-basic"
          onMouseUp={handleDropdown}
          className={
            props.errorMsg
              ? `${props.size ? styles[props.size] : null} ${
                styles.dropdown__invalidDropdown
                }`
              : `${props.size ? styles[props.size] : null}`
          }
        >
          <span className="ph-2">{selectedOption}</span>
          {props.showErrorIcon && props.errorMsg &&
            <span className={`${styles.rightAlertIcon}`}>
              <img src={alertIcon} alt='alert icon' width="20" height="20" />
            </span>
          }
          <span className={`${styles.right}`}>
            <img
              src={downarrow}
              alt="dropdown-arrow-down"
              className={!isClosed ? 'd-none' : ''}
              width={props.size === 'small' ? 10 : null}
              height={props.size === 'small' ? 10 : null}
            />
            <img
              src={chevronup}
              alt="dropdown-arrow-up"
              className={isClosed ? 'd-none' : ''}
              width={props.size === 'small' ? 10 : null}
              height={props.size === 'small' ? 10 : null}
            />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className={props.className}>
          {options && options.map((elem) => (
            <Dropdown.Item key={elem.id} onClick={(e) => handleChange(elem.id)} style={fontStyle}>
              {elem.value}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {props.errorMsg ? (
        <p className={styles.invalid}>{props.errorMsg}</p>
      ) : null}
    </>
  );
};

DropDownComp.propTypes = {
  size: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  defaultSelection: PropTypes.any.isRequired,
  feedback: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

DropDownComp.defaultProps = {
  size: 'small',
  valid: true,
  className: '',
  feedback: { wrong: 'Invalid input' },
};

export default DropDownComp;
