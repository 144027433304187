import React from 'react';
import GenericBanner from '../GenericBanner/index'
import SuccessDesktopBanner from '../../assets/images/success-banner-desktop.jpg'
import SuccessTabletBanner from '../../assets/images/success-banner-tablet.jpg'
import SuccessMobileBanner from '../../assets/images/success-banner-mobile.jpg'
import FailDesktopBanner from '../../assets/images/fail-banner-desktop.jpg'
import FailTabletBanner from '../../assets/images/fail-banner-tablet.jpg'
import FailMobiletBanner from '../../assets/images/fail-banner-mobile.jpg'


function SuccessComp(props) {
    return (
        props.success === true ? (
            <GenericBanner animate={props.animate} success={props.success} bannerDesktop={SuccessDesktopBanner} bannerTablet={SuccessTabletBanner} bannerMobile={SuccessMobileBanner} headerId="successMsg" msg={`Ref ID. ${props.refID}`} goBackToForm={props.goBackToForm}  />
        ) : (
            <GenericBanner animate={props.animate} success={props.success} bannerDesktop={FailDesktopBanner} bannerTablet={FailTabletBanner} bannerMobile={FailMobiletBanner} headerId="failMsg" backToFormId='backToForm' goBackToForm={props.goBackToForm}  />
        )
    )
    
}

export default SuccessComp
