import React from 'react';
import styles from './container.modules.scss'
import FormComp from '../../components/common/Form/index'
import getLocale from '../../assets/content';

function ContainerComp({submitData}) {

    return (
        <div className='formContainer' >
            <div className='form-group'>
                <h1>{getLocale()['cancelation']}</h1>
                <FormComp submitData={submitData} />
            </div>
            <div className='contact-us'>
                <h1>{getLocale()['callus']}</h1>
                <p className='timings'>{getLocale()['timings']}</p>
                <p className='phone'>{getLocale()['phone']}</p>
            </div>

        </div>
    )
}

export default ContainerComp